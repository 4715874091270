import type {LocationQueryValue} from 'vue-router';
import {useAppToast} from '~/stores/appToast';
import type {AssignmentList} from '~/types';
import type {AssignmentEngagementList} from '~/types/assignment-engagement-list.interface';

export const useAssignmentListStore = defineStore('assignmentListStore', () => {
  const {showApiErrorToast} = useAppToast();

  const assignmentList = ref<AssignmentList>();
  const currentAssignmentEngagementList = ref<AssignmentEngagementList>();

  function fetchAssignments(
    query: LocationQueryValue | LocationQueryValue[] = '',
    page: LocationQueryValue | LocationQueryValue[] = '1',
    noOffset = false,
  ) {
    let _count = Math.ceil(Number(page)) * 10 || 10;
    _count = _count > 0 ? _count : 10;

    const _offset = noOffset ? 0 : assignmentList.value?.items.length || 0;

    return useGetJobApi(`/assignments/search`, {
      method: 'GET',
      params: {
        query,
        count: _count,
        offset: _offset,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function setAssignmentList(list: AssignmentList | undefined) {
    assignmentList.value = list;
  }

  function addAssignmentListItems(list: AssignmentList) {
    if (!assignmentList.value) return;

    assignmentList.value.items = [
      ...(assignmentList.value?.items || []),
      ...list.items,
    ];

    assignmentList.value.offset = list.offset;
    assignmentList.value.count = list.count;
  }

  function $reset() {
    currentAssignmentEngagementList.value = undefined;
    assignmentList.value = undefined;
  }

  return {
    currentAssignmentEngagementList,
    assignmentList,
    setAssignmentList,
    addAssignmentListItems,
    fetchAssignments,
    $reset,
  };
});
